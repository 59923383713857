import { CssBaseline, Container, ThemeProvider, createTheme } from '@mui/material';

import Header from './components/Header'
import Body from './components/Body'
import Footer from './components/Footer'

const theme = createTheme();


function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Header/>
        <Body/>
        <Footer/>
      </Container>
    </ThemeProvider>
  )
}

export default App;
