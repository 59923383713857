import { Box, Card, CardMedia, Grid, Typography } from "@mui/material";

import gift1 from "../assets/BenzieGift1.jpeg";
import gift2 from "../assets/BenzieGift2.jpeg";
import gift3 from "../assets/BenzieGift3.jpeg";
import { openUrl } from "../utils";

const overlayStyles = {
  position: "absolute",
  opacity: "0.7",
  width: "100%",
  height: "100%",
  textAlign: "center",
  top: 0,
  left: 0,
};

const relativeContainerStyle = {
  position: "relative",
  background: "transparent",
  color: "transparent",
  cursor: "pointer",
  "&:hover": {
    ".MuiBox-root": {
      display: "initial !important",
      backgroundColor: "black",
      color: "white",
      transition: "all 0.4s ease",
    },
  },
};

export const BenzieGiftsPromo = () => {
  return (
    <Grid container spacing={3} m="0">
      <Grid item xs={12}>
        <Typography variant="h6">
          Shop Benzie Gifts to express your gratitude a little differently!
        </Typography>
      </Grid>
      <Grid item md={4} sm={12}>
        <Card
          sx={relativeContainerStyle}
          onClick={() =>
            openUrl(
              "https://benziegifts.com/collections/fall-2020-benzie-gifts-collection"
            )
          }
        >
          <CardMedia image={gift1} component="img" />
          <Box sx={overlayStyles}>
            <Typography variant="h3">Lemon Love</Typography>
          </Box>
        </Card>
      </Grid>
      <Grid item md={4} sm={12} position="relative">
        <Card
          sx={relativeContainerStyle}
          onClick={() =>
            openUrl(
              "https://benziegifts.com/collections/fall-2020-benzie-gifts-collection"
            )
          }
        >
          <CardMedia image={gift2} component="img" />
          <Box sx={overlayStyles}>
            <Typography variant="h3">Get Well Gift Basket</Typography>
          </Box>
        </Card>
      </Grid>
      <Grid item md={4} sm={12} position="relative">
        <Card
          sx={relativeContainerStyle}
          onClick={() =>
            openUrl("https://benziegifts.com/collections/corporate")
          }
        >
          <CardMedia image={gift3} component="img" />
          <Box sx={overlayStyles}>
            <Typography variant="h3">Recharge At Work</Typography>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};
