import { Grid } from "@mui/material";
import HotlineMessage from "./HotlineMessage";
import moment from 'moment';
import { BenzieGiftsPromo } from "./BenzieGifts";
import { HotlineCTA } from "./HotlineCTA";

const messages = [
  {from: 'Alice', time: moment().subtract(5, 'minutes'), message: 'I am incredibly grateful to Dr. Bob and the entire medical team at Hospital ABC for their perseverance over the last couple years.'},
  {from: 'Bob', time: moment().subtract(10, 'minutes'), message: 'I am incredibly grateful to Dr. Bob and the entire medical team at Hospital ABC for their perseverance over the last couple years.'},
  {from: 'Carol', time: moment().subtract(25, 'minutes'), message: 'I am incredibly grateful to Dr. Bob and the entire medical team at Hospital ABC for their perseverance over the last couple years.'},
  {from: 'Fairfax, Virginia', time: moment().subtract(27, 'minutes'), message: 'I am so grateful for all the nurses, therapists, and frontline workers that gave it their all.'},
  {from: 'Washington D.C.', time: moment().subtract(31, 'minutes'), message: 'I am so grateful for all the nurses, therapists, and frontline workers that gave it their all.'},
  {from: 'Philadelphia', time: moment().subtract(42, 'minutes'), message: 'I am so grateful for all the nurses, therapists, and frontline workers that gave it their all.'},
]

function Body() {
  return (
    <Grid container spacing={3} m="0">
      <HotlineCTA />
      {messages.map(msg => (
        <Grid item lg={4} md={6} sm={12} key={msg.time.format()}>
          <HotlineMessage msg={msg} />
        </Grid>
      ))}
      <BenzieGiftsPromo />
    </Grid>
  );
}

export default Body;
