import { Box, Link } from "@mui/material";

function Footer() {
  return (
    <Box display="flex" width="100%" justifyContent="space-between" p={2}>
      <div style={{ flex: 1, textAlign: "left" }} />
      <Link
        sx={{ flex: 1, textAlign: "center" }}
        href="mailto:info@gratcircle.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        info@gratcircle.com
      </Link>
      <div style={{ flex: 1, textAlign: "right" }}>
        Ⓒ 2021 Gratitude Circle, LLC
      </div>
    </Box>
  );
}

export default Footer;
