import { useRef, useState } from "react";
import { Toolbar, Box, Typography, MenuItem, Menu } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import useMediaQuery from "../hooks/useMediaQuery";
import { openUrl } from "../utils";

const logo = require("../assets/logo84.jpg");

const MenuContainer = ({ children }) => {
  const isDesktop = useMediaQuery("(min-width: 960px)");
  // const isDesktop = false;
  const [isOpen, setIsOpen] = useState(true);
  const anchorEl = useRef();

  const handleClick = () => {
    setIsOpen((prev) => !prev);
  };

  if (isDesktop) return <Box display="flex">{children}</Box>;
  else
    return (
      <>
        <MenuIcon ref={anchorEl} onClick={handleClick} />
        <Menu
          anchorEl={anchorEl.current}
          open={isOpen}
          onClose={() => setIsOpen(false)}
        >
          {children}
        </Menu>
      </>
    );
};

function Header() {
  return (
    <Toolbar
      sx={{
        display: "flex",
        justifyContent: "space-between",
        p: 2,
        borderBottom: "1px solid black",
      }}
    >
      <Box display="flex" alignItems="center">
        <img src={logo} style={{ width: "84px" }} alt="logo" />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          ml={2}
        >
          <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>
            Gratitude Circle<sup>®</sup> Hotline
          </Typography>
          <Typography>Dedicated to Gratitude and Positivity</Typography>
        </Box>
      </Box>

      <MenuContainer>
        <MenuItem onClick={() => openUrl("https://gratcircle.com")}>
          Home
        </MenuItem>
        <MenuItem onClick={() => openUrl("https://gratcircle.com/articles")}>
          Blog
        </MenuItem>
        <MenuItem onClick={() => openUrl("https://gratcircle.com/about")}>
          About
        </MenuItem>
        <MenuItem onClick={() => openUrl("https://gratcircle.com/contact")}>
          Contact
        </MenuItem>
        <MenuItem onClick={() => openUrl("https://gratcircle.com/shop")}>
          Shop
        </MenuItem>
      </MenuContainer>
    </Toolbar>
  );
}

export default Header;
