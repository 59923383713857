import { Button, Grid, Typography } from "@mui/material";

export const HotlineCTA = () => {
  return (
    <>
    <Grid item xs={8}>
      <Typography variant="h3">Hotline: +1 (979) 310 5162</Typography>
    </Grid>
    <Grid item xs={4}>
      <Button variant="contained">Call now</Button>
    </Grid>
    </>
  );
};
