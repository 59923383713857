import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// https://mui.com/material-ui/react-card/
export default function HotlineMessage({ msg }) {
  const { from, time, message } = msg;
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          From {from}, {time.fromNow()}
        </Typography>
        <Typography variant="h5" component="div">
          {message}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">LIKE</Button>
      </CardActions>
    </Card>
  );
}
